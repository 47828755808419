import React, {Component} from 'react';
import MultiSelectHelp from './MultiSelectHelp';
import ValidationService from '../services/ValidationService';
import ExportService from '../services/ExportService';
import questionnaire from '../resources/json/questionnaire';
import riskCoefficients from '../resources/json/riskCoefficients';
import SVGQuestion from '../resources/svg/question-circle-solid.svg';

class Home extends Component {

    constructor(props) {
        super(props);

        this.validationService = ValidationService;

        this.binaryValues = { yes: 1, no: 0, yesString: 'Yes', noString: 'No' };
        this.asaClassification = {
            noDisturb: 1,
            mildDisturb: 2,
            severeDisturb: 3,
            lifeThreaten: 4,
            moribund: 5
        };
        this.woundClassification = {
            clean: 1,
            cleanContaminated: 2,
            contaminated: 3,
            dirtyInfected: 4
        };

        this.stateUpdater = undefined;
        this.state = { data: { lengthOfStay: '', age: '' }, showHelp: false, calculatedRisk: null };

    }

    componentDidMount() {
        this._createState();
    }

    _createState = () => {
        const objs = Object.keys(questionnaire);

        objs.forEach(obj => {
            if (questionnaire[obj].binarySelections) {
                questionnaire[obj].binarySelections.forEach(binVal => {
                    this.stateUpdater = { ...this.stateUpdater, [binVal.field]: null };
                });
            }
            if (questionnaire[obj].multiSelection) {
                this.stateUpdater = { ...this.stateUpdater, [questionnaire[obj].multiSelection.field]: null };
            }
            if (questionnaire[obj].textField) {
                this.stateUpdater = { ...this.stateUpdater, [questionnaire[obj].textField.field]: '' };

            }
        });

        this.setState({ data: this.stateUpdater, showHelp: false, calculatedRisk: null, readmissionDeterminedRiskGroup: null });
    };

    onReset = () => {
        const classes = [ '.radioSelectionYes', '.radioSelectionNo', '.multiSelectionYes', '.multiSelectionNo' ];

        classes.forEach(tClass => {
            document.querySelectorAll(tClass).forEach(node => {
                if (tClass.includes('radioSelection')) {
                    node.className = 'radioSelection';
                }
                if (tClass.includes('multiSelection')) {
                    node.className = 'multiSelection';
                }
            });
        });

        this._clearErrorMessages();
        this._createState();

    };

    onBinarySelect = (field, value) => e => {

        let otherId = undefined;
        let idNum = undefined;
        let selectedValue = value;

        if (e.target.id.includes('y')) {
            idNum = e.target.id.split('y');
            otherId = 'n' + idNum[1];
            if (e.target.className === 'radioSelectionYes') {
                document.getElementById(e.target.id).className = 'radioSelection';
                selectedValue = null;
            } else {
                document.getElementById(e.target.id).className = 'radioSelectionYes';
                document.getElementById(otherId).className = 'radioSelection';
            }
        } else {
            idNum = e.target.id.split('n');
            otherId = 'y' + idNum[1];
            if (e.target.className === 'radioSelectionNo') {
                document.getElementById(e.target.id).className = 'radioSelection';
                selectedValue = null;
            } else {
                document.getElementById(e.target.id).className = 'radioSelectionNo';
                document.getElementById(otherId).className = 'radioSelection';
            }
        }

        this.stateUpdater[field] = selectedValue;
        this.setState({ data: this.stateUpdater });

    };

    onChangeLengthOfStay = e => {
        const numValidation = /[0-9]+/g;
        const nonNumeric = /[^0-9]/g;

        e.target.value && e.target.value.match(nonNumeric) === null ?
            this.stateUpdater.lengthOfStay = e.target.value.match(numValidation)[0] :
            this.stateUpdater.lengthOfStay = '';

        this.stateUpdater.lengthOfStay !== '' ?
            this.stateUpdater.lengthOfStay = this.stateUpdater.lengthOfStay.replace(/^0+/, '') :
            this.stateUpdater.lengthOfStay = '';
        this.setState({ data: this.stateUpdater });

    };

    onAsaSelection = value => e => {
        const cssId = 'asaSelection';

        value === this.stateUpdater[questionnaire.preoperativeVariables.multiSelection.field] ?
            this.stateUpdater[questionnaire.preoperativeVariables.multiSelection.field] = null :
            this.stateUpdater[questionnaire.preoperativeVariables.multiSelection.field] = value;

        this._setCssOnMultiSelection(cssId, e.target.id, this.stateUpdater[questionnaire.preoperativeVariables.multiSelection.field]);
        this.setState({ data: this.stateUpdater });
    };

    onWoundSelection = value => e => {
        const cssId = 'woundClassification';

        value === this.stateUpdater[questionnaire.woundOccurences.multiSelection.field] ?
            this.stateUpdater[questionnaire.woundOccurences.multiSelection.field] = null :
            this.stateUpdater[questionnaire.woundOccurences.multiSelection.field] = value;

        this._setCssOnMultiSelection(cssId, e.target.id, this.stateUpdater[questionnaire.woundOccurences.multiSelection.field]);
        this.setState({ data: this.stateUpdater });
    };

    _setCssOnMultiSelection = (cssId, id, value) => {
        const classNameSelection = 'multiSelection';
        const ignoreClasses = ['multiSelection', 'selectionTitle', 'tooltipIcon'];

        document.getElementById(cssId).childNodes.forEach(node => {
            if (!ignoreClasses.includes(node.className))
                node.className = classNameSelection;
        });

        value ?
            document.getElementById(id).className = 'multiSelectionYes' :
            document.getElementById(id).className = 'multiSelection'
    };

    onShowHelp = e => {
        this.setState({ showHelp: !this.state.showHelp });
    };

    onSubmit = e => {
        this._clearErrorMessages();

        this.validationService.setInputData(this.state.data);
        const validationErrors = this.validationService.validateInput();

        if (validationErrors.length > 0) {
            validationErrors.forEach(error => {
                document.getElementById('error' + error.field).innerText = error.message;
                document.getElementById('error' + error.field).style.display = 'block';
            });
        } else {
            const readmissionDeterminedRiskGroup = this._determineOrganSpaceSSIOccurrenceRisk();
            this.setState({ calculatedRisk: this._calculateRisk(), readmissionDeterminedRiskGroup, showExport: true });
        }
    };

    _clearErrorMessages = () => {
        Object.keys(this.state.data).forEach(field => {
            document.getElementById('error' + field).innerText = 'null';
            document.getElementById('error' + field).style.display = 'none';
        });
    };

    _calculateRisk = () => {
        const exponential = Math.exp(
            riskCoefficients.intercept.coefficient
            + riskCoefficients.age.coefficient * this.state.data.age
            + riskCoefficients.diabetes.coefficient * this.state.data.diabetes
            + riskCoefficients.disseminatedCancer.coefficient * this.state.data.disseminatedCancer
            + riskCoefficients.ventilatorDependent.coefficient * this.state.data.ventilatorDependent
            + riskCoefficients.hypertensionRequiringMedication.coefficient * this.state.data.hypertensionRequiringMedication
            + riskCoefficients.asaClassification.coefficient * this.state.data.asaClassification
            + riskCoefficients.steroidUse.coefficient * this.state.data.steroidUse
            + riskCoefficients.woundClassification.coefficient * this.state.data.woundClassification
            + riskCoefficients.lengthOfStay.coefficient * this.state.data.lengthOfStay
            + riskCoefficients.superficialSsiOccurrences.coefficient * this.state.data.superficialSsiOccurrences
            + riskCoefficients.deepIncisionalSsiOccurrences.coefficient * this.state.data.deepIncisionalSsiOccurrences
            + riskCoefficients.organSpaceSsiOccurrences.coefficient * this.state.data.organSpaceSsiOccurrences
            + riskCoefficients.pneumonia.coefficient* this.state.data.pneumonia
            + riskCoefficients.preoperativeSepsis.coefficient * this.state.data.preoperativeSepsis
            + riskCoefficients.pulmonaryEmbolismOccurrences.coefficient * this.state.data.pulmonaryEmbolismOccurrences
            + riskCoefficients.progressiveRenalInsufficiency.coefficient * this.state.data.progressiveRenalInsufficiency
            + riskCoefficients.urinaryTractInfectionOccurrences.coefficient * this.state.data.urinaryTractInfectionOccurrences
            + riskCoefficients.strokeCvaOccurrences.coefficient * this.state.data.strokeCvaOccurrences
            + riskCoefficients.cardiacArrestRequiringCprOccurrences.coefficient * this.state.data.cardiacArrestRequiringCprOccurrences
            + riskCoefficients.myocardialInfarctionOccurrences.coefficient * this.state.data.myocardialInfarctionOccurrences
            + riskCoefficients.woundDisruptionOccurrences.coefficient * this.state.data.woundDisruptionOccurrences
            + riskCoefficients.dvtThrombophlebitisOccurrences.coefficient * this.state.data.dvtThrombophlebitisOccurrences
            + riskCoefficients.sepsisOccurrences.coefficient * this.state.data.sepsisOccurrences
        );

        return (exponential) / (1 + exponential);
    };

    _determineOrganSpaceSSIOccurrenceRisk = () => {
        const riskValueEnum = { low: 'Low', medium: 'Medium', high: 'High' };
        const thresholdInDays = 12;

        if (this.state.data.organSpaceSsiOccurrences) {
            if (this.state.data.lengthOfStay < thresholdInDays ) {
                return riskValueEnum.high;
            } else {
                return riskValueEnum.medium
            }
        } else {
            return riskValueEnum.low;
        }
    };

    onChangeAge = e => {
        let tData = this.state.data;
        let tAge = e.target.value.replace(/[^0-9]+/, '');
        tAge = tAge.replace(/^0+(?=\d)/, '');

        tData.age = tAge;
        this.setState({ data: tData });
    };

    render() {
        return (
            <React.Fragment>
                <div className="row justify-content-center">
                    <h3 className="col-6" id="appHeader">Moffitt rEadmission RIsk Tool (MERIT) Calculator</h3>
                </div>
                <div className="sectionDivide"/>
                <div className="row">
                    <span id="selectionNotice">Each option requires a selection.  Please choose Yes or No.</span>
                </div>
                <div className="sectionDivide"/>
                <div className="row justify-content-center">
                    <div className="col-5 offset-1">
                        <h5>{questionnaire.preoperativeVariables.title}</h5>
                        <p/>
                        <div className="row selectionRow">
                            <div className="col-3" id="ageInput">
                                <input type="text" id="age" className="form-control" aria-describedby="age" maxLength="3" value={this.state.data.age} onChange={this.onChangeAge}/>
                            </div>
                            <div className="col-4" id="ageLabel">
                                {questionnaire.preoperativeVariables.textField.label}
                            </div>
                        </div>
                        <div className="row">
                            <div id={'error' + questionnaire.preoperativeVariables.textField.field} className="col-12 error">
                                null
                            </div>
                        </div>
                        { questionnaire.preoperativeVariables.binarySelections.map((obj, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <div className="row selectionRow">
                                        <div id={'y1' + i} className="radioSelection" onClick={this.onBinarySelect(obj.field, this.binaryValues.yes)}>{this.binaryValues.yesString}</div>
                                        <div id={'n1' + i} className="radioSelection" onClick={this.onBinarySelect(obj.field, this.binaryValues.no)}>{this.binaryValues.noString}</div>
                                        <span className="selectionTitle">{obj.label}</span>
                                    </div>
                                    <div className="row">
                                        <div id={'error' + obj.field} className="col-12 error">
                                            null
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                          })
                        }
                        <div id="asaSelection" className="row selectionRow">
                            <div id={'a' + this.asaClassification.noDisturb} className="multiSelection" onClick={this.onAsaSelection(this.asaClassification.noDisturb)}>{this.asaClassification.noDisturb}</div>
                            <div id={'a' + this.asaClassification.mildDisturb} className="multiSelection" onClick={this.onAsaSelection(this.asaClassification.mildDisturb)}>{this.asaClassification.mildDisturb}</div>
                            <div id={'a' + this.asaClassification.severeDisturb} className="multiSelection" onClick={this.onAsaSelection(this.asaClassification.severeDisturb)}>{this.asaClassification.severeDisturb}</div>
                            <div id={'a' + this.asaClassification.lifeThreaten} className="multiSelection" onClick={this.onAsaSelection(this.asaClassification.lifeThreaten)}>{this.asaClassification.lifeThreaten}</div>
                            <div id={'a' + this.asaClassification.moribund} className="multiSelection" onClick={this.onAsaSelection(this.asaClassification.moribund)}>{this.asaClassification.moribund}</div>
                            <span className="selectionTitle">{questionnaire.preoperativeVariables.multiSelection.label}</span>
                            <img className="tooltipIcon" src={SVGQuestion} alt="show-help" onClick={this.onShowHelp}/>
                        </div>
                        <div className="row">
                            <div id={'error' + questionnaire.preoperativeVariables.multiSelection.field} className="col-12 error">
                                null
                            </div>
                        </div>
                    </div>
                    <div className="col-5 offset-1">
                        <h5>{questionnaire.woundOccurences.title}</h5>
                        <p/>
                        { questionnaire.woundOccurences.binarySelections.map((obj, i) => {
                            return (
                                <React.Fragment  key={i}>
                                    <div className="row selectionRow">
                                        <div id={'y2' + i} className="radioSelection" onClick={this.onBinarySelect(obj.field, this.binaryValues.yes)}>{this.binaryValues.yesString}</div>
                                        <div id={'n2' + i} className="radioSelection" onClick={this.onBinarySelect(obj.field, this.binaryValues.no)}>{this.binaryValues.noString}</div>
                                        <span className="selectionTitle">{obj.label}</span>
                                    </div>
                                    <div className="row">
                                        <div id={'error' + obj.field} className="col-12 error">
                                            null
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                          })
                        }
                        <div id="woundClassification" className="row selectionRow">
                            <div id={'a2' + this.woundClassification.clean} className="multiSelection" onClick={this.onWoundSelection(this.woundClassification.clean)}>{this.woundClassification.clean}</div>
                            <div id={'a2' + this.woundClassification.cleanContaminated} className="multiSelection" onClick={this.onWoundSelection(this.woundClassification.cleanContaminated)}>{this.woundClassification.cleanContaminated}</div>
                            <div id={'a2' + this.woundClassification.contaminated} className="multiSelection" onClick={this.onWoundSelection(this.woundClassification.contaminated)}>{this.woundClassification.contaminated}</div>
                            <div id={'a2' + this.woundClassification.dirtyInfected} className="multiSelection" onClick={this.onWoundSelection(this.woundClassification.dirtyInfected)}>{this.woundClassification.dirtyInfected}</div>
                            <span className="selectionTitle">{questionnaire.woundOccurences.multiSelection.label}</span>
                            <img className="tooltipIcon" src={SVGQuestion} alt="show-help" onClick={this.onShowHelp}/>
                        </div>
                        <div className="row">
                            <div id={'error' + questionnaire.woundOccurences.multiSelection.field} className="col-12 error">
                                null
                            </div>
                        </div>
                    </div>
                </div>
                { this.state.showHelp &&
                    <MultiSelectHelp/>
                }
                <div className="sectionDivide"/>
                <div className="row justify-content-center">
                    <div className="col-5 offset-1">
                        <h5>{questionnaire.urinaryTractOccurrences.title}</h5>
                        <p/>
                        { questionnaire.urinaryTractOccurrences.binarySelections.map((obj, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <div className="row selectionRow">
                                        <div id={'y3' + i} className="radioSelection" onClick={this.onBinarySelect(obj.field, this.binaryValues.yes)}>{this.binaryValues.yesString}</div>
                                        <div id={'n3' + i} className="radioSelection" onClick={this.onBinarySelect(obj.field, this.binaryValues.no)}>{this.binaryValues.noString}</div>
                                        <span className="selectionTitle">{obj.label}</span>
                                    </div>
                                    <div className="row">
                                        <div id={'error' + obj.field} className="col-12 error">
                                            null
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                          })
                        }
                    </div>
                    <div className="col-5 offset-1">
                        <h5>{questionnaire.respiratoryOccurrences.title}</h5>
                        <p/>
                        { questionnaire.respiratoryOccurrences.binarySelections.map((obj, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <div className="row selectionRow">
                                        <div id={'y4' + i} className="radioSelection" onClick={this.onBinarySelect(obj.field, this.binaryValues.yes)}>{this.binaryValues.yesString}</div>
                                        <div id={'n4' + i} className="radioSelection" onClick={this.onBinarySelect(obj.field, this.binaryValues.no)}>{this.binaryValues.noString}</div>
                                        <span className="selectionTitle">{obj.label}</span>
                                    </div>
                                    <div className="row">
                                        <div id={'error' + obj.field} className="col-12 error">
                                            null
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                          })
                        }
                    </div>
                </div>
                <div className="sectionDivide"/>
                <div className="row justify-content-center">
                    <div className="col-5 offset-1">
                        <h5>{questionnaire.cardiacOccurrences.title}</h5>
                        <p/>
                        { questionnaire.cardiacOccurrences.binarySelections.map((obj, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <div className="row selectionRow">
                                        <div id={'y5' + i} className="radioSelection" onClick={this.onBinarySelect(obj.field, this.binaryValues.yes)}>{this.binaryValues.yesString}</div>
                                        <div id={'n5' + i} className="radioSelection" onClick={this.onBinarySelect(obj.field, this.binaryValues.no)}>{this.binaryValues.noString}</div>
                                        <span className="selectionTitle">{obj.label}</span>
                                    </div>
                                    <div className="row">
                                        <div id={'error' + obj.field} className="col-12 error">
                                            null
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                          })
                        }
                    </div>
                    <div className="col-5 offset-1">
                        <h5>{questionnaire.otherOccurrences.title}</h5>
                        <p/>
                        { questionnaire.otherOccurrences.binarySelections.map((obj, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <div className="row selectionRow">
                                        <div id={'y6' + i} className="radioSelection" onClick={this.onBinarySelect(obj.field, this.binaryValues.yes)}>{this.binaryValues.yesString}</div>
                                        <div id={'n6' + i} className="radioSelection" onClick={this.onBinarySelect(obj.field, this.binaryValues.no)}>{this.binaryValues.noString}</div>
                                        <span className="selectionTitle">{obj.label}</span>
                                    </div>
                                    <div className="row">
                                        <div id={'error' + obj.field} className="col-12 error">
                                            null
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                          })
                        }
                        <div className="row selectionRow">
                            <div className="col-3" id="lengtOfStayInput">
                                <input type="text" id="lengthOfStay" className="form-control" aria-describedby="lengthOfStay" maxLength="3" value={this.state.data.lengthOfStay} onChange={this.onChangeLengthOfStay}/>
                            </div>
                            <div className="col-6" id="lengthOfStayLabel">
                                {questionnaire.otherOccurrences.textField.label}
                            </div>
                        </div>
                        <div className="row">
                            <div id={'error' + questionnaire.otherOccurrences.textField.field} className="col-12 error">
                                null
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sectionDivide"/>
                <div className="row">
                    <div className="col-1 offset-1 submitButton rightSpacer" onClick={this.onSubmit}>
                        Calculate
                    </div>
                    <div className="col-1 resetButton" onClick={this.onReset}>
                        Reset
                    </div>
                    { this.state.calculatedRisk &&
                        <React.Fragment>
                            <div className="col-2 calculatedRiskLabel">
                                Calculated Risk:
                            </div>
                            <div className="col-1 calculatedRisk">
                                {this.state.calculatedRisk.toFixed(3)}
                            </div>
                            <div className="col-3">
                                Readmission Determined Risk Group:
                            </div>
                            <div className="col-1 calculatedRisk">
                                {this.state.readmissionDeterminedRiskGroup}
                            </div>
                        </React.Fragment>
                    }
                </div>
                <div className="smallSectionDivide"/>
                { this.state.calculatedRisk &&
                    <div className="row">
                        <div className="col-1 offset-1 exportButton rightSpacer" onClick={()=>ExportService.exportPdf(this.state.data)}>PDF</div>
                        <div className="col-1 exportButton" onClick={()=>ExportService.exportCsv(this.state.data, this.state.calculatedRisk, this.state.readmissionDeterminedRiskGroup)}>CSV</div>
                    </div>
                }
                <div className="sectionDivide"/>
            </React.Fragment>
        );
    }
}

export default Home;