import React from 'react';

export default () => {

    const asaClassificationHelp = () => {
        return (
            <React.Fragment>
            <div className="col-12">1: No Disturb</div>
            <div className="col-12">2: Mild Disturb</div>
            <div className="col-12">3: Severe Disturb</div>
            <div className="col-12">4. Life Threat</div>
            <div className="col-12">5: Moribund</div>
            </React.Fragment>
        )
    };

    const woundClassificationHelp = () => {
        return (
            <React.Fragment>
            <div className="col-12">1: Clean</div>
            <div className="col-12">2: Clean/Contaminated</div>
            <div className="col-12">3: Contaminated</div>
            <div className="col-12">4. Dirty Infected</div>
            </React.Fragment>
        )
    };

    return (
        <React.Fragment>
            <div className="helpBoxOutline">
                <div className="row">
                    <div className="col-5 offset-1">
                        <h6>ASA Classification Definitions</h6>
                    </div>
                    <div className="col-5 offset-1">
                        <h6>Wound Classification Definitions</h6>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-5 offset-1">
                        {asaClassificationHelp()}
                    </div>
                    <div className="col-5 offset-1">
                        {woundClassificationHelp()}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}