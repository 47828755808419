import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import StateService from '../services/StateService';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
      <Route {...rest} render={(props) => StateService.isSessionValid() === true
        ? <Component {...props}/>
        : <Redirect to={{ pathname: '/', state: { from: props.location} }}/>}
      />
  );
};