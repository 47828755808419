class StateService {

    createSession = () => {
        sessionStorage.setItem('PMDAccept', 'true');
    };

    isSessionValid = () => {
        return !!sessionStorage.getItem('PMDAccept');
    };

}

export default new StateService();