import React from 'react';
import StateService from '../services/StateService';

export default () => {

    const RISK_CALCULATOR_ENDPOINT = '/calculate/risk';
    let URL = '';

    if (process.env.REACT_APP_PRODUCTION === 'true') {
        URL += 'http://' + process.env.REACT_APP_HOST_NAME;
    } else {
        URL += 'http://' + process.env.REACT_APP_HOST_NAME + ':' + process.env.REACT_APP_REACT_PORT;
    }

    URL += RISK_CALCULATOR_ENDPOINT;

    const onClick = () => {
        StateService.createSession();
        window.location = URL;
    };

    return (
        <React.Fragment>
            <div className="row justify-content-center">
                <div className="col-4" id="appHeader">
                    <h3>PanModel Risk Calculator</h3>
                </div>
            </div>
            <p/>
            <div className="row justify-content-center">
                <div className="col-10">
                    <h4>Disclaimer:</h4>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-10">
                    The estimates are calculated using information from the ACS NSQIP database which stores
                    information from patients who had a surgical procedure similar to the one the patient may have.
                    Please note that the risk percentages provided by this Readmission Risk Calculator are only estimates.
                    There may be other factors that are not included in the estimate which may increase or decrease
                    the risk of being readmitted within 30 days post-operation. The institution is not responsible for
                    medical decisions that may be made based on the risk calculator estimates.
                </div>
            </div>
            <p/>
            <div className="row justify-content-center">
                <div className="col-2 submitButton" id="enterButton" onClick={onClick}>
                    <span>Enter</span>
                </div>
            </div>
        </React.Fragment>
    )
}