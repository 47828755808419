import React from 'react';

export default () => {
    return (
        <React.Fragment>
            <div className="row justify-content-center">
                <div className="col-6" id="appFooterDivider"> </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-12" id="appFooter">
                    © Copyright 2020 H. Lee Moffitt Cancer Center & Research Institute - Powered by BBSR
                </div>
            </div>
        </React.Fragment>
    )
}