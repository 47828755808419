import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import Home from './components/Home';
import Disclaimer from './components/Disclaimer';
import {PrivateRoute} from './components/PrivateRoute';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <React.Fragment>
      {(process.env.REACT_APP_PRODUCTION === 'false' || process.env.REACT_APP_UAT === 'true') &&
            <div className="row" id="developmentBanner">
              <div className="col-4">
                DEVELOPMENT
              </div>
              <div className="col-4">
                DEVELOPMENT
              </div>
              <div className="col-4">
                DEVELOPMENT
              </div>
            </div>
          }
      <div className="container">
          <BrowserRouter>
              <p/>
              <Route exact path="(/|/home|/disclaimer)" component={Disclaimer}/>
              <PrivateRoute path="(/calculate/risk)" component={Home}/>
          </BrowserRouter>
          <p/>
          <Footer/>
      </div>
    </React.Fragment>
  );
}

export default App;
