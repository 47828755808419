import * as jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';

class ExportService {

    exportCsv = (data, calculatedRsk, readmissionDeterminedRiskGroup) => {

        const HEADERS = ['field', 'value'];
        let csvStr = '';

        HEADERS.forEach((header, i) => {
            i + 1 === HEADERS.length ? csvStr += header + '\n' : csvStr += header + ',';
        });

        Object.keys(data).forEach(field => {
            csvStr += field + ',' + data[field] + '\n';
        });

        csvStr += 'calculatedRisk,' + calculatedRsk + '\n';
        csvStr += 'readmissionDeterminedRiskGroup,' + readmissionDeterminedRiskGroup;

        const blob = new Blob([csvStr], {type: 'text/csv;charset=utf-8;'});
        const encodedUri = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.setAttribute('href', encodedUri);
        link.setAttribute('download', this._getDate() + '_panmodel.csv');
        link.style.visibility = 'hidden';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    };

    exportPdf = data => {
        html2canvas(document.body).then(canvas => {
            const pdf = new jsPDF('p', 'in');
            pdf.addImage(canvas, 'JPG', 0, 0.25, 8.5, 8.5);
            pdf.save(this._getDate() + '_panmodel.pdf');
        });
    };

    _getDate = () => {
        let date = new Date();
        let month = null;
        let day = null;

        date.getMonth() + 1 < 10 ? month = '0' + String(date.getMonth() + 1) : month = date.getMonth();
        date.getDate() < 10 ? day = '0' + String(date.getDate()) : day = date.getDate();

        return date.getFullYear() + String(month) + String(day);
    };

}

export default new ExportService();