import questionnaire from '../resources/json/questionnaire';

class ValidationService {

    constructor(inputData) {
        this.iputData = inputData;
    }

    setInputData = inputData => { this.inputData = inputData };

    getInputData = () => { return this.inputData };

    validateInput = () => {
        const validationErrors = [];
        let message = null;

        Object.keys(this.inputData).forEach(obj => {
            if (this.inputData[obj] === null || this.inputData[obj] === undefined || this.inputData[obj] === '') {

                if (obj === questionnaire.otherOccurrences.textField.field || obj === questionnaire.preoperativeVariables.textField.field) {
                    message= 'This field requires an input value';
                } else {
                    message= 'This field requires a selection';
                }

                validationErrors.push({
                    field: obj,
                    err: 'null field value',
                    errorType: 'nullField',
                    message
                });
            }
        });

        if (this.inputData[questionnaire.otherOccurrences.textField.field] < 0 || this.inputData[questionnaire.otherOccurrences.textField.field] > 120) {
            validationErrors.push({ field: questionnaire.otherOccurrences.textField.field, err: 'value out of range [0-120]', errType: 'valueRange', message: 'Please enter a value between [0-120]' });
        }

        if (this.inputData[questionnaire.preoperativeVariables.textField.field] < 0 || this.inputData[questionnaire.preoperativeVariables.textField.field] > 99) {
            validationErrors.push({ field: questionnaire.preoperativeVariables.textField.field, err: 'value out of range [0-99]', errType: 'valueRange', message: 'Please enter a value between [0-99]' });
        }

        return validationErrors;
    };

}

export default new ValidationService();